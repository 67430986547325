<template>
    <b-overlay :show="showLoader" class="bussinesPrivacyPolicy">
        <b-card title="Splošni pogoji varovanja osebnih podatkov" class="w-100">
            <b-row v-if="$hasPermissions($permissions.EditPages)">
                <b-col cols="12" sm="6" class="text-center text-sm-left">
                    <b-button variant="primary" @click="$router.push({name: 'business-privacy-policy-add', params: {manager: 'basic'}})">Dodaj pogoje</b-button>
                </b-col>
            </b-row>
            <div v-if="basicPrivacyPolicies && basicPrivacyPolicies.length > 0">
                <b-alert v-if="!hasBeenDeleted && $hasPermissions($permissions.EditPages)" class="my-1 p-1 text-center" show variant="warning">Z vlečenjem vrstic lahko spremenite vrstni red prikaza na spletni strani.</b-alert>
                <b-alert v-else-if="$hasPermissions($permissions.EditPages)" class="mt-1 p-1 text-center" show variant="warning">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če so pogoji po brisanju še vedno vidni, ponovno naložite stran.</b-alert>
                <div class="row mt-3 pb-1 header">
                    <div class="col-4">
                        Naslov
                    </div>
                    <div class="col-8">
                        Vsebina
                    </div>
                </div>
                <draggable
                    v-model="basicPrivacyPolicies"
                    tag="ul"
                    class="list-group list-group-flush cursor-move"
                    id="drag"
                    :disabled="!$hasPermissions($permissions.EditPages)"
                >

                    <b-list-group-item
                        v-for="(item, index) in basicPrivacyPolicies"
                        :key="index"
                        tag="li"
                        class="p-0 py-1"
                    >
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                {{item.title}}
                            </div>
                            <div class="col-5 cut-text" v-html="item.content"/>
                            <div class="col-3 d-flex justify-content-center" v-if="$hasPermissions($permissions.EditPages)">
                                <b-button variant="warning mr-2" @click="$router.push({name: 'business-privacy-policy-edit', params: {id: item.id}})"><fa icon="edit"/></b-button>
                                <b-button variant="danger" @click="deleteObject(item.id)"><fa icon="trash"/></b-button>
                            </div>
                        </div>
                    </b-list-group-item>
                </draggable>
                <div class="d-flex justify-content-end mt-2" v-if="$hasPermissions($permissions.EditPages)">
                    <b-button class="mt-2" variant="primary" @click="basicSaveOrder()">Shrani vrstni red</b-button>
                </div>
            </div>

            <div class="mt-2" v-else>
                <b-alert class="p-1 text-center" show variant="warning">Ni dodanih pogojev o obdelavi osebnih podatkov</b-alert>
            </div>

        </b-card>
        <b-card title="Pogoji varovanja osebnih podatkov Habit d.o.o." class="w-100">
            <b-row v-if="$hasPermissions($permissions.EditPages)">
                <b-col cols="12" sm="6" class="text-center text-sm-left">
                    <b-button variant="primary" @click="$router.push({name: 'business-privacy-policy-add', params: {manager: 'habit'}})">Dodaj pogoje</b-button>
                </b-col>
            </b-row>
            <div v-if="habitPrivacyPolicies && habitPrivacyPolicies.length > 0">
                <b-alert v-if="!hasBeenDeleted && $hasPermissions($permissions.EditPages)" class="my-1 p-1 text-center" show variant="warning">Z vlečenjem vrstic lahko spremenite vrstni red prikaza na spletni strani.</b-alert>
                <b-alert v-else-if="$hasPermissions($permissions.EditPages)" class="mt-1 p-1 text-center" show variant="warning">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če so pogoji po brisanju še vedno vidni, ponovno naložite stran.</b-alert>
                <div class="row mt-3 pb-1 header">
                    <div class="col-4">
                        Naslov
                    </div>
                    <div class="col-8">
                        Vsebina
                    </div>
                </div>
                <draggable
                    v-model="habitPrivacyPolicies"
                    tag="ul"
                    class="list-group list-group-flush cursor-move"
                    id="drag"
                    :disabled="!$hasPermissions($permissions.EditPages)"
                >

                    <b-list-group-item
                        v-for="(item, index) in habitPrivacyPolicies"
                        :key="index"
                        tag="li"
                        class="p-0 py-1"
                    >
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                {{item.title}}
                            </div>
                            <div class="col-5 cut-text" v-html="item.content"/>
                            <div class="col-3 d-flex justify-content-center" v-if="$hasPermissions($permissions.EditPages)">
                                <b-button variant="warning mr-2" @click="$router.push({name: 'business-privacy-policy-edit', params: {id: item.id}})"><fa icon="edit"/></b-button>
                                <b-button variant="danger" @click="deleteObject(item.id)"><fa icon="trash"/></b-button>
                            </div>
                        </div>
                    </b-list-group-item>
                </draggable>
                <div class="d-flex justify-content-end mt-2" v-if="$hasPermissions($permissions.EditPages)">
                    <b-button class="mt-2" variant="primary" @click="habitSaveOrder()">Shrani vrstni red</b-button>
                </div>
            </div>

            <div class="mt-2" v-else>
                <b-alert class="p-1 text-center" show variant="warning">Ni dodanih pogojev o obdelavi osebnih podatkov</b-alert>
            </div>

        </b-card>
        <b-card title="Pogoji varovanja osebnih podatkov Staninvest d.o.o." class="w-100">
            <b-row v-if="$hasPermissions($permissions.EditPages)">
                <b-col cols="12" sm="6" class="text-center text-sm-left">
                    <b-button variant="primary" @click="$router.push({name: 'business-privacy-policy-add', params: {manager: 'stanInvest'}})">Dodaj pogoje</b-button>
                </b-col>
            </b-row>
    
            <div v-if="stanInvsetPrivacyPolicies && stanInvsetPrivacyPolicies.length > 0">
                <b-alert v-if="!hasBeenDeleted && $hasPermissions($permissions.EditPages)" class="my-1 p-1 text-center" show variant="warning">Z vlečenjem vrstic lahko spremenite vrstni red prikaza na spletni strani.</b-alert>
                <b-alert v-else-if="$hasPermissions($permissions.EditPages)" class="mt-1 p-1 text-center" show variant="warning">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če so pogoji po brisanju še vedno vidni, ponovno naložite stran.</b-alert>
    
                <div class="row mt-3 pb-1 header">
                    <div class="col-4">
                        Naslov
                    </div>
                    <div class="col-8">
                        Vsebina
                    </div>
                </div>
    
                <draggable
                    v-model="stanInvsetPrivacyPolicies"
                    tag="ul"
                    class="list-group list-group-flush cursor-move"
                    id="drag"
                    :disabled="!$hasPermissions($permissions.EditPages)"
                >
    
                    <b-list-group-item
                        v-for="(item, index) in stanInvsetPrivacyPolicies"
                        :key="index"
                        tag="li"
                        class="p-0 py-1"
                    >
                        <div class="row d-flex align-items-center">
                            <div class="col-4">
                                {{item.title}}
                            </div>
                            <div class="col-5 cut-text" v-html="item.content"/>
                            <div class="col-3 d-flex justify-content-center" v-if="$hasPermissions($permissions.EditPages)">
                                <b-button variant="warning mr-2" @click="$router.push({name: 'business-privacy-policy-edit', params: {id: item.id}})"><fa icon="edit"/></b-button>
                                <b-button variant="danger" @click="deleteObject(item.id)"><fa icon="trash"/></b-button>
                            </div>
                        </div>
                    </b-list-group-item>
                </draggable>
                <div class="d-flex justify-content-end mt-2" v-if="$hasPermissions($permissions.EditPages)">
                    <b-button class="mt-2" variant="primary" @click="stanInvestSaveOrder()">Shrani vrstni red</b-button>
                </div>
            </div>
    
            <div class="mt-2" v-else>
                <b-alert class="p-1 text-center" show variant="warning">Ni dodanih pogojev o obdelavi osebnih podatkov</b-alert>
            </div>
    
        </b-card>
    </b-overlay>
</template>

<script>
    import draggable from 'vuedraggable'
    import {BListGroupItem, BAlert, BButton, BOverlay, BCard, BRow, BCol} from 'bootstrap-vue'
    export default {
        components: {
            // Table,
            draggable,
            BListGroupItem,
            BAlert,
            BButton,
            BOverlay,
            BCard,
            BRow,
            BCol
        },
        data() {
            return {
                showLoader: false,
                basicPrivacyPolicies: [],
                habitPrivacyPolicies: [],
                stanInvsetPrivacyPolicies: [],
                hasBeenDeleted: false
            }
        },
        methods:{
            async deleteObject(id) {
                this.hasBeenDeleted = false
                const deleteConfirmationResult = await this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati pogoj.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                })
                if (!deleteConfirmationResult) return

                try {
                    await this.$http.delete(`/api/management/v1/business_privacy_policies/${id}`)
                    this.hasBeenDeleted = true
                    this.$printSuccess('Pogoji so bili izbrisani')
                    await this.loadData()
                } catch (err) {
                    this.$printError('Prišlo je do napake pri brisanju')
                }
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const responseBasic = this.$http.get('/api/management/v1/business_privacy_policies/manager/basic')
                    const responseHabit = this.$http.get('/api/management/v1/business_privacy_policies/manager/habit')
                    const responseStanInvest = this.$http.get('/api/management/v1/business_privacy_policies/manager/stanInvest')

                    const responses = await Promise.all([responseBasic, responseHabit, responseStanInvest])
                    this.basicPrivacyPolicies = responses[0].data
                    this.habitPrivacyPolicies = responses[1].data
                    this.stanInvsetPrivacyPolicies = responses[2].data
                } catch (err) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                }
            },
            updateSortPlace(object, manager) {
                return this.$http.patch(`/api/management/v1/business_privacy_policies/sort/${object.id}/${manager}`, object)
            },
            async basicSaveOrder() {
                try {
                    for (const id in this.basicPrivacyPolicies) {
                        const index = parseInt(id)
                        if (index + 1 !== this.basicPrivacyPolicies[index].sort) {
                            this.basicPrivacyPolicies[index].sort = index + 1
                            await this.updateSortPlace(this.basicPrivacyPolicies[index], 'basic')
                        }
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake pri posodabljanju vrstnega reda')
                } finally {
                    this.$printSuccess('Vrstni red uspesno posodobljen')
                }
            },
            async habitSaveOrder() {
                try {
                    for (const id in this.habitPrivacyPolicies) {
                        const index = parseInt(id)
                        if (index + 1 !== this.habitPrivacyPolicies[index].sort) {
                            this.habitPrivacyPolicies[index].sort = index + 1
                            await this.updateSortPlace(this.habitPrivacyPolicies[index], 'habit')
                        }
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake pri posodabljanju vrstnega reda')
                } finally {
                    this.$printSuccess('Vrstni red uspesno posodobljen')
                }
            },
            async stanInvestSaveOrder() {
                try {
                    for (const id in this.stanInvsetPrivacyPolicies) {
                        const index = parseInt(id)
                        if (index + 1 !== this.stanInvsetPrivacyPolicies[index].sort) {
                            this.stanInvsetPrivacyPolicies[index].sort = index + 1
                            await this.updateSortPlace(this.stanInvsetPrivacyPolicies[index], 'stanInvest')
                        }
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake pri posodabljanju vrstnega reda')
                } finally {
                    this.$printSuccess('Vrstni red uspesno posodobljen')
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style>
.bussinesPrivacyPolicy #drag .sortable-chosen{
    background: #eaf3f9;
}
.bussinesPrivacyPolicy .header{
    font-weight: bold;
    border-bottom: 1px solid grey ;
}
.bussinesPrivacyPolicy .cut-text{
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>